import React from "react"
import styled from "styled-components"
import BreadCrumbs from "../breadcrumbs"
import Img from "gatsby-image/withIEPolyfill"
import SVG from "../svg"
import HeroRow from "../hero-row"

const HeroAreaStyled = styled.div`
  position: relative;
  max-width: 2560px;
  margin: 93px auto 0 auto;

  @media (max-width: 768px) {
    min-height: 690px;
  }

  .background {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    max-width: 2560px;
    height: 690px;
    margin: 0 auto;
    top: 0;
    z-index: 1;
    position: absolute;
    width: 100%;
  }

  .gatsby-image-wrapper {
    width: 100%;
    min-height: 690px;
  }

  .topline {
    color: ${(p) => p.fields.hero_area.top_line_color || p.theme.DarkBlue};
  }

  .headline {
    color: ${(p) => p.fields.pack_info.color_1 || p.theme.DarkBlue};

    @media (max-width: 768px) {
      font-size: 5em;
    }
  }
`

const HeroArea = ({ fields, children }) => {
  const trail = [
    {
      label: "FLAVORS",
      link: "/flavors/",
    },
    {
      label: fields.pack_info.name,
      link: "",
    },
  ]

  const sources = fields.hero_area.mobile_image
    ? [
        {
          media: "(min-width: 769px)",
          ...fields.hero_area.image.localFile.childImageSharp.fluid,
        },
        {
          media: "(max-width: 768px)",
          ...fields.hero_area.mobile_image.localFile.childImageSharp.fluid,
        },
      ]
    : fields.hero_area.image
    ? [
        {
          ...fields.hero_area.image.localFile.childImageSharp.fluid,
        },
      ]
    : []

  return (
    <HeroAreaStyled fields={fields}>
      <div className="background">
        {fields.hero_area.image ? (
          <Img
            alt={fields.pack_info.name}
            imgStyle={{
              zIndex: 1,
            }}
            fluid={sources}
          />
        ) : (
          ""
        )}
      </div>
      <HeroRow>
        <BreadCrumbs trail={trail} />
        <h1 className="heroH1">
          <div
            className="topline"
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: fields.hero_area.top_line,
            }}
          />
          <div
            className="headline"
            dangerouslySetInnerHTML={{
              __html: fields.pack_info.name,
            }}
            tabIndex="0"
          />
        </h1>
        <SVG
          name="SWave"
          color={fields.hero_area.wave_color || fields.pack_info.color_2}
        />{" "}
        {children}
      </HeroRow>
    </HeroAreaStyled>
  )
}

export default HeroArea
