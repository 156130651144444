import React, { useContext } from "react"
import { graphql } from "gatsby"
import styled, { ThemeContext } from "styled-components"
import Layout from "../layout"
import SEO from "../seo"
import ResponsiveSpacing from "../responsive-spacing"
import Feature from "../feature"
import Heading from "../heading"
import HeroArea from "./hero"
import RoughEdge from "../rough-edge"
import ConnectedPacks from "../packs"
import QuoteBanner from "../quote-banner"
import Flavor from "../flavor"
import BottomColorNav from "../bottom-color-nav"
import Button from "../button"
import SVG from "../svg"
import { connect } from "react-redux"
import Garnish from "../garnish"
import PromoSticker from "../promo-sticker"

const BannerStyled = styled.div`
  background-color: ${(p) => p.color};
  position: relative;

  > .inner {
    padding: 45px 0;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  }

  h2 {
    font-family: "interstate-compressed";
    font-size: 6em;
    margin: 0;
    color: ${(p) => p.fields.pack_info.color_1 || p.theme.DarkBlue};
  }

  .svg {
    @media (max-width: 768px) {
      width: 90%;
      margin: 0 auto;
    }
  }
`

const Banner = ({ fields, children, color }) => {
  return (
    <BannerStyled fields={fields} color={color}>
      <RoughEdge className="top" color={color} />
      <div className="inner">{children}</div>
      <RoughEdge className="bottom" color={color} />
    </BannerStyled>
  )
}

const ContentStyled = styled.div`
  .orgCert {
    position: absolute;
    right: 50%;
    margin-right: -550px;
    height: 148px;
    top: -100px;
    transform: rotate(25deg);
  }

  .heroButton {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .cont {
    position: relative;
  }

  .price {
    margin: 15px 0;
    color: ${(p) => p.theme.DarkBlue};
    font-size: 24px;
    font-weight: bold;
    font-family: "Montserrat";
  }

  .linkout {
    font-size: 0.55em;
    text-decoration: none;
    color: ${(p) => p.fields.hero_area.link_color || p.theme.DarkBlue};
    @media (max-width: 768px) {
      font-size: 0.25em;
    }

    .svg {
      position: relative;
      height: 18px;
      top: 2px;
      margin-left: 6px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .holder {
    z-index: 1;
    position: relative;

    > .inner {
      padding: 45px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      background: ${(p) => p.theme.White};
    }
  }

  .similar {
    margin-bottom: 150px;
  }

  .certs {
    display: flex;
    text-align: center;
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 0;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      .icon {
        width: 30%;
      }
    }
  }

  .certs .icon {
    margin: 10px auto;
    height: 50px;
  }

  .similarCol {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }

  .feature-inner {
    max-width: 600px;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    width: 100%;
  }
  .left {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 5%;
    width: 50%;
    height: 500px;
  }
  .right {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5%;
    width: 50%;
    height: 500px;
  }
  .left .bg {
    opacity: 0.4;
  }
  .right .bg {
    opacity: 0.6;
  }
  .gatsby-image-wrapper {
    /* height: 400px; */
  }
  .recommendCarton {
    width: 80%;
    text-align: center;
  }
  @media (max-width: 768px) {
    .nutritionFeature {
      flex-wrap: wrap;
    }
    .left {
      height: auto;
      padding: 60px 15px;
      width: 100%;
      justify-content: center;
    }
    .right {
      height: auto;
      padding: 60px 15px;
      width: 100%;
      justify-content: center;
    }
  }

  .explore {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
`

const PintsStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(${(p) => p.pintNumber}, 1fr);
  max-width: 1400px;
  margin: 0 auto;

  .smaller {
    margin-left: 12px;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const Pints = ({ fields, allFlavors }) => {
  let pints = []
  const pint_ids = fields.pints.map((p) => p.wordpress_id)
  allFlavors.map((f) => {
    if (pint_ids.includes(f.wordpress_id)) pints.push(f.acf)
  })
  return (
    <PintsStyled pintNumber={pints.length}>
      {pints.map((p, i) => {
        return (
          <Flavor
            key={i}
            fields={p}
            index={i}
            inactive={true}
            width={131}
            height={139}
            promoSmaller={true}
            {...p.flavor}
            nutrition_feature={p.nutrition_feature}
          />
        )
      })}
    </PintsStyled>
  )
}

const Content = ({ pack, dispatch, data, context }) => {
  const theme = useContext(ThemeContext)
  const post = data.pack.edges[0].node
  const fields = post.acf
  const allPacks = data.similar_packs.edges.map((e) => e.node)
  const allFlavors = data.flavors.edges.map((e) => e.node)
  const similar_item_ids = fields.similar_items.selected_packs.map(
    (s) => s.wordpress_id
  )
  let more_packs = []
  allPacks.map((f) => {
    similar_item_ids.map((sii) => {
      if (f.wordpress_id === sii) {
        more_packs.push(f)
      }
    })
  })

  const image = fields.pack_info.image.localFile.childImageSharp.fluid.src
  const { promotion, name, price, sku, short_info } = fields.pack_info
  const pints = fields.pints
  const garnishes = fields.garnish || []

  const flavorIds = pints.map((p) => p.wordpress_id)
  const includedPints = allFlavors
    .filter((flavor) => flavorIds.includes(flavor.wordpress_id))
    .map((fl) => fl.acf.flavor)
    .map((fl) => ({ name: fl.name, sku: fl.sku }))
  const product = {
    name,
    image,
    pints: includedPints,
    price,
    sku,
    short_info,
  }

  const nfs = fields.pack_info.not_for_sale
  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = fields
  return (
    <ContentStyled fields={fields}>
      <SEO
        title={search_title || name}
        description={search_description}
        headline={share_headline || name}
        share_comment={share_comment}
        image={share_image || image}
      />
      <HeroArea fields={fields}>
        <div className="price">${price}</div>
        <div className="heroButton">
          {promotion === "sold-out" ? (
            <Button icon="+" disabled={true}>
              ADD TO CART
            </Button>
          ) : (
            <Button
              onClick={(e) => {
                e.preventDefault()
                dispatch({ type: "ADD_TO_CART", product })
              }}
            >
              ADD TO CART
            </Button>
          )}
        </div>
        <PromoSticker promotion={promotion} />
      </HeroArea>
      <div className="holder">
        <RoughEdge className="top" color={"white"} />
        <div className="inner">
          <Heading margin="0">
            <h2>{fields.opening.headline}</h2>
            <h3>{fields.opening.sub_heading}</h3>
          </Heading>
        </div>
      </div>

      <Pints fields={fields} allFlavors={allFlavors} />

      <Banner fields={fields} color={fields.pack_info.color_2}>
        <h2>{fields.certifications_headline}</h2>
        <div className="certs">
          {fields.certifications.map((c, i) => (
            <SVG
              key={i}
              name={c}
              className="icon"
              color={fields.pack_info.color_1}
            />
          ))}
        </div>
      </Banner>
      <ResponsiveSpacing>
        <Feature
          align="left"
          title={fields.feature.headline}
          sub_heading={fields.feature.sub_heading}
          summary={fields.feature.summary}
          image={fields.feature.image}
          button_label={fields.feature.button_label}
          wave_color={fields.pack_info.color_1}
          button_link={fields.feature.button_link}
          margin="150px auto"
        />
        <SVG
          name="OrganicCert"
          className="orgCert"
          color={fields.pack_info.color_2}
        />
      </ResponsiveSpacing>

      <QuoteBanner
        opacity={1}
        text_color={theme.White}
        quote={fields.call_out_text}
        garnishes={fields.garnish}
        color={fields.pack_info.color_1}
      >
        <Garnish
          garnish={garnishes[0]}
          pSpeed={-0.1}
          top={0}
          width={150}
          rotation={-35}
          rSpeed={-0.02}
          pos={"left"}
          style={{}}
        />
        <Garnish
          garnish={garnishes[1]}
          pSpeed={-0.1}
          top={-300}
          width={150}
          rotation={100}
          rSpeed={0.05}
          pos={"right"}
          style={{}}
        />
      </QuoteBanner>
      <ResponsiveSpacing>
        <Heading margin="150px 0 0 0">
          <h2>{fields.similar_items.headline}</h2>
          <h3>{fields.similar_items.sub_heading}</h3>
        </Heading>

        <ConnectedPacks packs={more_packs} page={fields} flavors={allFlavors} />
      </ResponsiveSpacing>

      <BottomColorNav />
    </ContentStyled>
  )
}

const ConnectedContent = connect((state) => state)(Content)

export default (props) => {
  return (
    <Layout>
      <ConnectedContent data={props.data} context={props.pageContext} />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    pack: allWordpressWpPacks(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          acf {
            opening {
              headline
              sub_heading
            }
            search_title
            search_description
            share_headline
            share_comment
            share_image {
              source_url
            }
            pack_info {
              not_for_sale
              promotion
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              color_1
              color_2
              name
              sku
              price
              short_info
            }
            call_out_text
            feature {
              headline
              sub_heading
              summary
              wave_color
              button_label
              button_link
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 450, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            certifications
            certifications_headline
            pints {
              post_title
              wordpress_id
            }
            similar_items {
              headline
              sub_heading
              selected_packs {
                wordpress_id
              }
            }
            garnish {
              image {
                localFile {
                  childImageSharp {
                    fixed(width: 150, quality: 100) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
            hero_area {
              top_line
              top_line_color
              wave_color
              headline_color
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 2560, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              mobile_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    flavors: allWordpressWpFlavors(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          wordpress_id
          acf {
            flavor {
              sku
              not_for_sale
              name
              name_with_html
              tags
              color_1
              color_2
              price
              promotion
              carton_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 300, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              inside_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 300, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            nutrition_feature {
              headline
              summary
              wave_color
              color_override
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 450, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    similar_packs: allWordpressWpPacks(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          wordpress_id
          acf {
            pack_info {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 290, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              hover_image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 290, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              name
              name_with_html
              promotion
              sku
              price
              short_info
            }
            pints {
              post_title
              wordpress_id
            }
          }
        }
      }
    }
  }
`
